import React, { useState } from "react";
import { TileLayer } from "react-leaflet";
import { greatCircle, intersect, point, polygon } from "@turf/turf";
import L from 'leaflet';
import TiffOverlay from '../../libs/TiffTile';
import { read_tiff_file } from '../../libs/TiffTile.jsx';
import axios from "axios";
import { getListSegmentations } from "../../services/api";
import tiff_example from "../../assets/images/wind_speed.tif";

const checkArray = (arrays, array) => arrays.some(a => {
    return (a.length > array.length ? a : array).every((_, i) => a[i] === array[i]);
});

const tile2long = (x, z) => { return (x / Math.pow(2, z) * 360 - 180); }

const tile2lat = (y, z) => {
    var n = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
    return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
}

const list_tif = ["ireland_1.tif", "mask.tif", "ireland_2.tif", "ireland_3.tif"]

function BaseMapLayer({ mapRef, useMapEvents, firstLoad, setFirstLoad, listBboxes, set_list_bboxes, listDisplayedImgs, setListDisplayedImgs, addCarbonValue, subtractCarbonValue, setCarbonValue, msgZoomIn, setMsgZoomIn, prevZoomLv, setPrevZoomLv, setCanDraw }) {
    const [curIdx, setcurIdx] = useState(0);
    // var list_cur_imgs = [[1, 2, 1]];

    async function handle_tiff(tif_url, x, y, z, mapBounds, carbon_stock) {
        // const tif_url = "http://localhost:8002/static/" + img_file
        // read_tiff_file(url);
        console.log(tif_url)
        const load_tiff = await read_tiff_file(tif_url).then((response) => {
            console.log("URL after loam loaded", response.imageURL);
            console.log("bounds after loam loaded", response.wgs84GeoCorners);
            let tiff_layer = L.imageOverlay(response.imageURL, response.wgs84GeoCorners, { interactive: false, opacity: 1 });
            // L.tooltip().setLatLng([(response.wgs84GeoCorners[0][0]+response.wgs84GeoCorners[2][0])/2, (response.wgs84GeoCorners[0][1]+response.wgs84GeoCorners[1][1])/2]).setContent(`(x,y,z) = (${x}, ${y}, ${z})`).addTo(map);

            // tiff_layer.on("load", (e) => {
            //     console.log(e.type)
            //     console.log(e)
            //     const w = parseInt(e.target._image.style.width.replace("px", ""));
            //     console.log("width", w)
            //     const h = parseInt(e.target._image.style.height.replace("px", ""));
            //     console.log("height", h)
            //     var canvas = document.createElement("canvas");
            //     canvas.width = w;
            //     canvas.height = h;
            //     const context = canvas.getContext("2d");
            //     // console.log(e.target._image)
            //     context.drawImage(e.target._image, 0, 0, w, h);
            //     var img_data = context.getImageData(0, 0, w, h).data;
            //     console.log("all pixel intensity of img", img_data)
            //     var sum = img_data.reduce((partialSum, a) => partialSum + a, 0);
            //     addCarbonValue(sum);
            //     console.log("sum of img", sum)
            //     var feature = e.target.feature = e.target.feature || {}; // Initialize feature

            //     feature.type = feature.type || "Feature"; // Initialize feature.type
            //     var props = feature.properties = feature.properties || {}; // Initialize feature.properties
            //     props.carbon_value = sum;
            // });

            // .then((response) => {addCarbonValue(response)});
            //   setTiffLayer(tiff_layer);
            console.log("tiff layer added", tiff_layer);
            // console.log("Bounds of map: ", map.getBounds())
            tiff_layer.carbon_stock = carbon_stock;
            tiff_layer.addTo(map);
            // map.fitBounds(response.wgs84GeoCorners, { "maxZoom": 15 });

            // var new_obj = {
            //     "carbon_value":,
            //     "layer":,
            //     "onLoaded": true
            // }
            const new_list_bboxes = [...listBboxes, tiff_layer];
            set_list_bboxes(new_list_bboxes);
            // L.popup(response.wgs84GeoCorners[0],
            //   {content:`<p>${carbonValue}</p>`})
            // .openOn(map);
        });
    };

    const map = useMapEvents({
        zoomend: (e) => {
            console.log("Map zoom end event!")
            const zoom_lv = e.target.getZoom();
            console.log("cur zoom lv: ", zoom_lv);
            console.log("prev zoom lv: ", prevZoomLv);
            setPrevZoomLv(zoom_lv);
            if (zoom_lv < 16 && prevZoomLv >= 16) {
                // console.log("Show msg zoom in at level: ", zoom_lv);
                // const new_msg = ""
                setMsgZoomIn(true);
                // map.on('draw:drawstart')
                setCanDraw(false);
            }
            if (zoom_lv >= 16 && prevZoomLv < zoom_lv) {
                setMsgZoomIn(false);
                // map.on('draw:drawstart')
                setCanDraw(true);
            }
            // handleTileLoad(e);
        },
        dragend: (e) => {
            console.log("Map dragend event!")
            handleTileLoad(e);
        },
        resize: (e) => {
            console.log("Map resize event!")
            handleTileLoad(e);
        }
    });

    const handleTileLoad = (e) => {
        var list_cur_imgs = [[1, 2, 1]];
        console.log("loaded tile layer from event", console.log(e))
        const centerPoint = mapRef.getCenter();
        console.log("mapCenter", centerPoint);
        const mapBounds = mapRef.getBounds();
        console.log("map bounds", mapBounds);
        const curZoomLevel = mapRef.getZoom();
        console.log("zoom level", curZoomLevel);
        // console.log("size of map (in pixels)", mapRef.getSize());
        // console.log("bounds of map", mapRef.getPixelBounds());
        var requested_obj = {
            top_lat: mapBounds._northEast.lat,
            top_lon: mapBounds._southWest.lng,
            btm_lat: mapBounds._southWest.lat,
            btm_lon: mapBounds._northEast.lng,
            mask_format: "png"
        };
        console.log("Requested area: ", requested_obj)

        if (curZoomLevel >= 16) {

            if (listBboxes && listBboxes.length > 0) {
                // console.log("check intersection")
                for (const bbox of listBboxes) {
                    // console.log(bbox)
                    const bbox_bound = bbox.getBounds()
                    var isIntersected = mapBounds.intersects(bbox_bound)
                    if (isIntersected) {
                        // carbon_stock += bbox.feature.properties.carbon_value;
                    } else {
                        // console.log("no intersect bbox")
                        // console.log(bbox)
                        // console.log("bbox value: ", bbox.carbon_stock);
                        // subtractCarbonValue(bbox.carbon_stock);
                        mapRef.removeLayer(bbox);
                    }

                }
            }
            // handle_tiff(tiff_example, 1, 1, 1, mapBounds);

            getListSegmentations(requested_obj).then((response) => {
                console.log("Response from Alex", response)
                console.log(response.data.length)
                return response.data
            }).then((v) => {
                var new_list_displayed_imgs = [];
                var carbon_stock = 0;
                // v.map(seg => {
                //     carbon_stock += seg.carbon_stock
                //     if (checkArray(listDisplayedImgs, [seg.x, seg.y, seg.z])) {

                //     }
                //     else {
                //         new_list_displayed_imgs = [...new_list_displayed_imgs, [seg.x, seg.y, seg.z]];
                //     }

                // });
                // setListDisplayedImgs([...listDisplayedImgs, ...new_list_displayed_imgs]);
                v.map(seg => {
                    // console.log("element");
                    // console.log(seg);
                    // console.log("List displayed images");
                    // console.log(listDisplayedImgs);
                    // console.log("cur imgs");
                    // console.log(list_cur_imgs);
                    // var concat_new_list_displayed_imgs = [...listDisplayedImgs, ...new_list_displayed_imgs];
                    carbon_stock += seg.carbon_stock
                    if (checkArray(list_cur_imgs, [seg.x, seg.y, seg.z]) | checkArray(listDisplayedImgs, [seg.x, seg.y, seg.z]) | checkArray(new_list_displayed_imgs, [seg.x, seg.y, seg.z])) {
                        // console.log("Img displayed!");
                        // console.log("x,y, z: ", [seg.x, seg.y, seg.z])
                    }
                    else {
                        list_cur_imgs = [...list_cur_imgs, [seg.x, seg.y, seg.z]];
                        new_list_displayed_imgs = [...new_list_displayed_imgs, [seg.x, seg.y, seg.z]];
                        // console.log("list updated of displayed images");
                        // console.log(list_cur_imgs);
                        const lat_img = tile2lat(seg.y, seg.z);
                        const lon_img = tile2long(seg.x, seg.z);
                        console.log("new imgs x,y,z: ", [seg.x, seg.y, seg.z]);
                        // console.log("Lat lon from Alex: ", seg.lat, seg.lon);
                        const seg_mask_bound = [[seg.top_lat, seg.top_lon], [seg.top_lat, seg.btm_lon], [seg.btm_lat, seg.btm_lon], [seg.btm_lat, seg.top_lon]]
                        var seg_mask_layer = L.imageOverlay(seg.mask_url, seg_mask_bound, { interactive: false, opacity: 1 });
                        seg_mask_layer.addTo(mapRef);
                        // const new_list_bboxes = [...listBboxes, seg_mask_layer];
                        // set_list_bboxes(new_list_bboxes);
                        // handle_tiff(seg.mask_url, seg.x, seg.y, seg.z, mapBounds);
                    }
                });
                setListDisplayedImgs([...listDisplayedImgs, ...new_list_displayed_imgs]);
                console.log("carbon stock from Alex: ", carbon_stock);
                addCarbonValue(carbon_stock);

            }).catch((error) => {
                console.log(error);
            });
        }
        else {
            console.log("zoom lv is less than 16 ===> dont request api")
        }
    }

    return (
        // <TileLayer
        //     eventHandlers={{
        //         load: (e) => {
        //             if (firstLoad){
        //                 handleTileLoad(e);
        //                 setFirstLoad(false);
        //             }
        //         }
        //     }}
        //     attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        //     // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        //     url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        // ></TileLayer>
        <TileLayer
            eventHandlers={{
                load: (e) => {
                    if (firstLoad){
                        handleTileLoad(e);
                        setFirstLoad(false);
                    }
                }
            }}
            url='https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidmlldGxlYWZsZXRhcGkiLCJhIjoiY2t5anV4amc0MjZvdzJ3bGtobmZoYzAzZiJ9.eylvVomC-0f2p-FA0EgW4w'
            attribution= '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Mapbox map</a></strong>'
        ></TileLayer>
    );
};

export default BaseMapLayer;