import React, { Component, useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import loam from 'loam';
import L from "leaflet";
import proj4 from "proj4";
import { useLeafletContext } from "@react-leaflet/core"
import "leaflet-geotiff-2";
import "leaflet-geotiff-2/dist/leaflet-geotiff-rgb";
import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
// import loam from "loam";
// var parse_georaster = require("georaster");
// var GeoRasterLayer = require("georaster-layer-for-leaflet");
// const gdal = require('gdal-async');
window.proj4 = proj4;

const renderer = L.LeafletGeotiff.rgb();

const options = {
  // Optional, band index to use as R-band
  rBand: 1,
  // Optional, band index to use as G-band
  gBand: 2,
  // Optional, band index to use as B-band
  bBand: 3,
  // band index to use as alpha-band
  // NOTE: this can also be used in combination with transpValue, then referring to a
  // color band specifying a fixed value to be interpreted as transparent
  alphaBand: 0,
  // for all values equal to transpValue in the band alphaBand, the newly created alpha
  // channel will be set to 0 (transparent), all other pixel values will result in alpha 255 (opaque)
  transpValue: 0,
  renderer: renderer,
};

export const read_tiff_file = async (tif_url) => {
  let blob_tiff = await fetch(tif_url).then(r => r.blob());
  console.log("blob tiff", blob_tiff);
  const dataset = await loam.open(blob_tiff);
  console.log("loam dataset", dataset);
  const wktDest =
    'GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.01745329251994328,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]]';
  const smallDataset = await dataset.convert([
    '-of',
    'GTiff',
    '-outsize',
    '1000',
    '0',
    '-r',
    'nearest'
  ]);
  dataset.close();
  // const smallDataset = dataset;
  const newDataset = await smallDataset.warp([
    '-co',
    'PREDICTOR=2',
    '-dstnodata',
    '0',
    '-of',
    'GTiff',
    '-t_srs',
    'EPSG:3857',
    '-r',
    'near'
  ]);
  smallDataset.close();
  // const newDataset = smallDataset;
  const width = await newDataset.width();
  const height = await newDataset.height();
  const geoTransform = await newDataset.transform();
  const wktSource = await newDataset.wkt();
  const corners = [[0, 0], [width, 0], [width, height], [0, height]];
  const geoCorners = corners.map(function (coords) {
    var x = coords[0];
    var y = coords[1];
    return [
      geoTransform[0] + geoTransform[1] * x + geoTransform[2] * y,
      geoTransform[3] + geoTransform[4] * x + geoTransform[5] * y
    ];
  });
  const wgs84GeoCornersGdal = await loam.reproject(
    wktSource,
    wktDest,
    geoCorners
  );
  const wgs84GeoCorners = wgs84GeoCornersGdal.map((coords) => [coords[1], coords[0]]);
  console.log("wgs84", wgs84GeoCorners)
  const pngDataset = await newDataset.convert(['-of', 'PNG']);
  console.log("img bytes", pngDataset)
  const imageBytes = await pngDataset.bytes();
  pngDataset.close()
  const outputBlob = new Blob([imageBytes], { type: 'image/png' });
  const imageURL = window.URL.createObjectURL(outputBlob);
  // const imageOverlay = L.imageOverlay(imageURL, wgs84GeoCorners);
  // const imageBounds = imageOverlay.getBounds();
  // const zoom = this.mapRef.current.leafletElement.getBoundsZoom(imageBounds);
  // const center = imageBounds.getCenter();
  // return imageURL, wgs84GeoCorners, zoom, center
  console.log("inside read tiff file");
  console.log(imageURL);
  console.log(wgs84GeoCorners);
  return { imageURL, wgs84GeoCorners }
}

// const getTiffLayer = (url) => {
//   fetch(url)
//     .then(response => response.arrayBuffer())
//     .then(arrayBuffer => {
//       parse_georaster(arrayBuffer).then(georaster => {
//         console.log("georaster:", georaster);
//         var layer = new GeoRasterLayer({
//           georaster: georaster,
//           opacity: 0.7,
//           pixelValuesToColorFn: values => values[0] === 42 ? '#ffffff' : '#000000',
//           resolution: 64 // optional parameter for adjusting display resolution
//         });
//         return layer
//       });
//     });
// };
const get_color_intensity = (overlay) => {
  const w = parseInt(overlay._image.style.width.replace("px", ""));
  console.log("width", w)
  const h = parseInt(overlay._image.style.height.replace("px", ""));
  console.log("height", h)
  var canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  const context = canvas.getContext("2d");
  console.log(overlay._image)
  context.drawImage(overlay._image, 0, 0, w, h);
  var img_data = context.getImageData(0, 0, w, h).data;
  console.log("all pixel intensity of img", img_data)
  const sum = img_data.reduce((partialSum, a) => partialSum + a, 0);
  return sum
}

function TiffOverlay({ map, img_file, addCarbonValue, subtractCarbonValue }) {
  const [tiffLayer, setTiffLayer] = useState(null);
  const [carbonValue, setCarbonValue] = useState(0);
  useEffect(() => {
    async function a() {
      const tif_url = "http://localhost:8002/static/" + img_file
      // read_tiff_file(url);
      console.log(tif_url)
      const load_tiff = await read_tiff_file(tif_url).then((response) => {
        console.log("URL after loam loaded", response.imageURL);
        console.log("bounds after loam loaded", response.wgs84GeoCorners);
        let tiff_layer = L.imageOverlay(response.imageURL, response.wgs84GeoCorners, {interactive: false});
        tiff_layer.on("load", (e) => {
            console.log(e.type)
            console.log(e)
            const w = parseInt(e.target._image.style.width.replace("px", ""));
            console.log("width", w)
            const h = parseInt(e.target._image.style.height.replace("px", ""));
            console.log("height", h)
            var canvas = document.createElement("canvas");
            canvas.width = w;
            canvas.height = h;
            const context = canvas.getContext("2d");
            // console.log(e.target._image)
            context.drawImage(e.target._image, 0, 0, w, h);
            var img_data = context.getImageData(0, 0, w, h).data;
            console.log("all pixel intensity of img", img_data)
            const sum = img_data.reduce((partialSum, a) => partialSum + a, 0);
            setCarbonValue(sum);
            addCarbonValue(sum);
            console.log("sum of img", sum)
            return sum
          });
          // .then((response) => {addCarbonValue(response)});
        setTiffLayer(tiff_layer);
        console.log("tiff layer added", tiff_layer);
        // console.log("Bounds of map: ", map.getBounds())
        tiff_layer.addTo(map);
        map.fitBounds(response.wgs84GeoCorners);
        console.log("carbon value after loading image: ", carbonValue)
        // L.popup(response.wgs84GeoCorners[0],
        //   {content:`<p>${carbonValue}</p>`})
        // .openOn(map);
      });
        // const sum_of_intensity = get_color_intensity(tiff_layer);
        // console.log("sum of intensity", sum_of_intensity);
      };
    a();
  }, [])

  useEffect(() => {
    if (tiffLayer !== null) {
      
      return () => {
        console.log("remove image");
        // console.log("Subtract function", subtractCarbonValue);
        subtractCarbonValue(carbonValue);
        map.removeLayer(tiffLayer);
      }
    }
  }, [tiffLayer, map])
  return null;
}

export default TiffOverlay;
