import React, { useState } from "react";
import { Button, Checkbox, Form, Input } from 'antd';
import { Space, Typography } from 'antd';
const { Text, Link } = Typography;

const onChangeForest = (e) => {
    console.log("Change value forest");
    console.log(e.target.checked);
  };

const onChangeMangrove = (e) => {
    console.log("Change value mangrove");
    console.log(e.target.checked);
};

function LayerChoicesForm({forestLayerChanger, mangroveLayerChanger, distanceChanger}){
    const legend_width = 20;

    return (
    <Form>
        <Form.Item label="Forest Checkbox" name="disabled" valuePropName="unchecked">
            <Checkbox onChange={(e) => {forestLayerChanger(e.target.checked); distanceChanger(e.target.checked)}}>Forest Carbon</Checkbox>
        </Form.Item>
        <Form.Item label="Mangrove Checkbox" name="disabled" valuePropName="unchecked">
            <Checkbox onChange={(e) => {mangroveLayerChanger(e.target.checked); distanceChanger(e.target.checked)}}>Mangrove Carbon</Checkbox>
        </Form.Item>
    </Form>
    );
}

export default LayerChoicesForm;