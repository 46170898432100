
import { Button, Checkbox, Form, Input, Select, Divider } from 'antd';
import { useState } from 'react';
const { Option } = Select;


const UploadImageForm = ({ finishedPreview, previewImage, initCord }) => {
    const [form] = Form.useForm();
    const [rotDeg, setRotDeg] = useState(0);
    const [tllat, setTopLeftLat] = useState(initCord[0]);
    const [tllng, setTopLeftLng] = useState(initCord[1]);
    const [brlat, setBottomRightLat] = useState(initCord[2]);
    const [brlng, setBottomRightLng] = useState(initCord[3]);
    const [imgType, setImgType] = useState("uav");
    const [isPublic, setIsPublic] = useState(true);
    // const onCreate = (values) => {
    //     console.log('Received values of form: ', values);
    //     setOpen(false);
    // };

    const onTopLeftLatChange = (e) => {
        const newvalue = parseFloat(e.target.value || '0');
        setTopLeftLat(newvalue);
    };

    const onTopLeftLngChange = (e) => {
        const newvalue = parseFloat(e.target.value || '0');
        setTopLeftLng(newvalue);
    };

    const onBottomRightLatChange = (e) => {
        const newvalue = parseFloat(e.target.value || '0');
        setBottomRightLat(newvalue);
    };

    const onBottomRightLngChange = (e) => {
        const newvalue = parseFloat(e.target.value || '0');
        setBottomRightLng(newvalue);
    };

    const onRotDegChange = (e) => {
        const newvalue = parseInt(e.target.value || '0', 10);
        setRotDeg(newvalue);
    };

    const onImgTypeChange = (newImgType) => {
        setImgType(newImgType);
    };

    const onCheckboxChange = (e) => {
        setIsPublic(e.target.checked);
    };

    return (
        <Form
            form={form}
            onFinish={(values) => finishedPreview(values)}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
                modifier: 'public',
                topLeftLat: tllat,
                topLeftLng: tllng,
                bottomRightLat: brlat,
                bottomRightLng: brlng,
                ImgType: imgType
            }}
        >
            {/* <Form.Item>
                <img
                    alt="preview"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Form.Item> */}
            <Form.Item >
                <Checkbox checked={isPublic} onChange={onCheckboxChange}>
                    Public
                </Checkbox>
            </Form.Item>
            <Form.Item
                name="Location"
                label="Location"
                rules={[
                    {
                        required: true,
                        message: 'Please input the location!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Divider orientation='left'>Top Left Coordinates</Divider>
            <Form.Item name="topLeftLat" label="Latitude" style={{display:'inline-block'}}>
                <Input
                    type="text"
                    value={tllat}
                    defaultValue={tllat}
                    onChange={onTopLeftLatChange}
                    style={{
                        width: 150,
                    }}
                />
            </Form.Item>
            <Form.Item name="topLeftLng" label="Longitude" style={{display:'inline-block'}}>
                <Input
                    type="text"
                    value={tllng}
                    defaultValue={tllng}
                    onChange={onTopLeftLngChange}
                    style={{
                        width: 150,
                    }}
                />
            </Form.Item>
            <Divider orientation='left'>Bottom Right Coordinates</Divider>
            <Form.Item name="bottomRightLat" label="Latitude" style={{display:'inline-block'}}>
                <Input
                    type="text"
                    value={brlat}
                    defaultValue={brlat}
                    onChange={onBottomRightLatChange}
                    style={{
                        width: 150,
                    }}
                />
            </Form.Item>
            <Form.Item name="bottomRightLng" label="Longitude" style={{display:'inline-block'}}>
                <Input
                    type="text"
                    value={brlng}
                    defaultValue={brlng}
                    onChange={onBottomRightLngChange}
                    style={{
                        width: 150,
                    }}
                />
            </Form.Item>
            <Form.Item name="rotDeg" label="Rotation Degrees">
                <Input type='text' value={rotDeg} onChange={onRotDegChange} style={{width: 150}}/>
            </Form.Item>
            <Form.Item name='ImgType' label='Type'>
                <Select
                    // value={imgType}
                    defaultValue={imgType}
                    style={{
                        width: 150,
                        margin: '0 0px',
                    }}
                    onChange={onImgTypeChange}
                >
                    <Option value="uav">UAV</Option>
                    <Option value="satellite">SATELLITE</Option>
                    <Option value="phone">PHONE</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="Description"
                label="Description"
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Button key="submit" type="primary" htmlType="submit">
                    submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UploadImageForm