import './App.css';
import './assets/css/Label.css';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  MapContainer,
  ScaleControl,
  ImageOverlay,
  LayerGroup,
  FeatureGroup,
  useMap,
  ZoomControl,
  Marker,
  Popup,
  useMapEvents,
} from 'react-leaflet';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import { useCookies } from 'react-cookie';
import L, { latLng } from 'leaflet';
import { LatLng } from 'leaflet';
import { EditControl } from 'react-leaflet-draw'
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './App.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import { addressPoints } from './data/realworld.10000';
import { OpenStreetMapProvider, GeoSearchControl, } from 'leaflet-geosearch';
import { Button, Layout, Menu, Modal, } from 'antd';
import { Space, Typography } from 'antd';
// import "leaflet-geosearch/dist/geosearch.css";
import BaseMap from './components/HomeScreen/BaseMap';
import GuessToolBar from './components/HomeScreen/GuessToolBar';
import GuessNavBar from './components/HomeScreen/GuessNavBar';
import UserToolBar from './components/HomeScreen/UserToolBar';
import UserNavBar from './components/HomeScreen/UserNavBar';
import Legend from './components/HomeScreen/Legend';
import TiffOverlay from './libs/TiffTile';
import { getCarbonStockInPolygon } from './services/api';
const { Header, Content, Sider } = Layout;
const { Text, Link } = Typography;
// const [cookies, setCookie, removeCookie] = useCookies(['intro']);
// -33.870971, 151.212967  
// -33.873576, 151.230999 yatch habor sydney
// -33.878260, 151.266534 kend sydney

const init_center = [51.8934893, -8.4946454]
const init_zoom = 16;
const host = "http://localhost:8002/";


function Map(props) {
  const [map, setMap] = useState(null); {/* state for map reference, use for accessing map object */ }
  const [turnOnLayer1, setTurnOnLayer1] = useState(false); {/* for turning on carbon layer (only for old version) */ }
  const [turnOnLayer2, setTurnOnLayer2] = useState(false); {/* for turning on mangrove layer (only for old version) */ }
  const [legendDistance, setLegendDistance] = useState(0); {/* for turning on legend (only for old version) */ }
  const [markerPos, setMarkerPos] = useState(null); {/* location of marker after searching location (only for old version) */ }
  const [showMarker, setShowMarker] = useState(false); {/* for displaying marker after searching location (only for old version) */ }
  const [listImg, setListImg] = useState([]); {/* list carbon stock overlaid images */ }
  const [carbonValue, setCarbonValue] = useState(0); {/* displayed carbon stock value */ }
  const [listBboxes, setListBboxes] = useState([]); {/* list images in current viewport */ }
  const [listDisplayedImgs, setListDisplayedImgs] = useState([]); {/* list loaded images */ }
  const [msgZoomIn, setMsgZoomIn] = useState(false); {/* show message when user zoom out below lv 16 */ }
  const [prevZoomLv, setPrevZoomLv] = useState(init_zoom); {/* set previous zoom lv */ }
  const [canDraw, setCanDraw] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  // const [showIntro, setShowIntro] = useState(cookies.get("showed"))

  const changeLegendDistance = (e) => {
    //set position for legend when open layer 1 and layer 2
    console.log("Change distance")
    console.log(legendDistance)
    if (e) {
      setLegendDistance(legendDistance + 20);
    }
    else {
      setLegendDistance(legendDistance - 20)
    }
  }

  const hanldeOnZoomEnd = () => {
    console.log("Zoom end!")
    const zoom_lv = map.getZoom();
    if (zoom_lv < 16) {
      console.log("zoom level is too small: ", zoom_lv)
      setMsgZoomIn(true);
    }
    else {
      console.log("zoom level is normal: ", zoom_lv)
      setMsgZoomIn(false);
    }

  };

  const addCarbonValue = (v) => {
    //add carbon stock value
    console.log("Current carbon stock: ", carbonValue)
    console.log("Call add up value: ", v)

    // var carbon_sum = 0;
    // for (var item in v) {
    //   carbon_sum += item.feature.properties.carbon_value;
    // };

    // setCarbonValue(carbon_sum);
    // setCarbonValue(v+carbonValue);
    setCarbonValue(v);
  };

  const subtractCarbonValue = (v) => {
    //subtract carbon value
    console.log("Current carbon stock: ", carbonValue)
    console.log("Call subtract value: ", v)
    if (carbonValue >= v) {
      setCarbonValue(carbonValue - v);
    }
    else {
      setCarbonValue(0);
    }
  }

  const searchOnMap = async (searchQuery) => {
    //search service and add marker to location of interest
    const provider = new OpenStreetMapProvider();
    // const map = useMap();
    const searchResults = await provider.search({ query: searchQuery });
    console.log(searchResults)
    if (searchResults.length >= 1) {
      const searchCords = new LatLng(parseFloat(searchResults[0].y), searchResults[0].x)
      map.flyTo(searchCords, 16, { animate: false, duration: 20, easeLinearity: 1 });
      // map.panTo(searchCords)
      setShowMarker(true);
      setMarkerPos(searchCords);
    }
  }

  const addImg = (value, previewUrl, previewUid) => {
    //add images from uploaded list of images from user
    // setImageUrl(preview);
    const checkExistedImg = listImg.filter(item => (item.uid === previewUid))
    if (checkExistedImg.length == 0) {
      let newListImg = [...listImg, {
        key: listImg.length,
        url: previewUrl,
        uid: previewUid,
        // tlLat: value.Lat,
        // tlLng: value.Lng,
        bounds: [[value.topLeftLat, value.topLeftLng], [value.bottomRightLat, value.bottomRightLng]],
        opacity: 0.6,
        type: value.ImgType,
        region: value.Location,
        description: value.Description
      }]
      console.log("after add image")
      console.log(newListImg);
      setListImg(newListImg);
    }
    else {
      let newListImg = listImg;
      newListImg.forEach(item => {
        if (item.uid == previewUid) {
          // item.lat = value.Lat;
          // item.lng = value.Lng;
          item.bounds = [[value.topLeftLat, value.topLeftLng], [value.bottomRightLat, value.bottomRightLng]];
          // opacity: 0.6,
          item.type = value.ImgType;
          item.region = value.Location;
          item.description = value.Description;
        }
      })
      console.log("after add image")
      console.log(newListImg);
      setListImg(newListImg);
    }
  }

  const removeImg = (previewUid) => {
    //remove images from uploaded list
    let newListImg = listImg.filter(item => !(item.uid === previewUid));
    console.log("after remove")
    console.log(newListImg)
    setListImg(newListImg);
  }

  const removeMarker = (pos) => {
    // remove marker of location of interest after search
    setShowMarker(false);
  }

  const handleOnDrawCreate = (event) => {
    //handle polygon on created event 
    console.log("draw create event");
    console.log(event);
    var list_points = []
    if (event && event.layerType === "polygon") {
      console.log("create polygon done");
      console.log(event.layer.getLatLngs());
      list_points = event.layer.getLatLngs()[0].map((v) => { return { lat: v.lat, lon: v.lng } });
    }
    if (event && event.layerType === "rectangle") {
      console.log("create rectangle done");
      console.log(event.layer.getLatLngs());
      list_points = event.layer.getLatLngs()[0].map((v) => { return { lat: v.lat, lon: v.lng } });
    }

    console.log("list point of polygon", list_points)
    const center_of_shape = event.layer.getCenter();
    var carbon_stock_within_shape = 0;
    console.log("Center of shape: ", center_of_shape);
    const zoom_lv = event.target.getZoom();

    if (zoom_lv >= 16) {
      getCarbonStockInPolygon(list_points).then((v) => {
        console.log("polygon response: ", v);
        carbon_stock_within_shape = v.carbon_stock;
        event.layer.carbon_stock = carbon_stock_within_shape;
        // var carbonStockPopup = L.popup(center_of_shape, { content: carbon_stock_within_shape > 0 ? `<p>${Number((carbon_stock_within_shape).toFixed(2))} tCO2e</p>` : "No data!" });
        var carbon_tooltip = L.tooltip(center_of_shape, { content: carbon_stock_within_shape > 0 ? `<p>${Number((carbon_stock_within_shape).toFixed(2))} tCO2e</p>` : "No data!", direction: "center" });
        event.layer.carbon_popup = carbon_tooltip
        event.layer.on('mouseover', function (e) {
          console.log("Hover polygon");
          console.log(e);
          e.target._map.openTooltip(event.layer.carbon_popup);
        });
        event.target.openTooltip(event.layer.carbon_popup);
        // carbon_tooltip.addTo(event.target);
      })

    }
    else {
      // event.target.openPopup(L.popup(center_of_shape, {content: `<p>Please zoom in</p>`}));
    }
  }

  const handleOnDrawStart = (event) => {
    if (event.target.getZoom() < 16) {
      console.log("when draw start");
      // event.target.off("draw:drawstart")
      // event.off("baselayerchange");
      // setCanDraw(false);
    }
  }

  const handleOnEdited = (event) => {
    //handle polygon on edited event 
    console.log("time to stop editing");
    console.log(event);
    if (event && event.layerType === "polygon") {
      console.log("edit polygon stop");
      // console.log(event.layer.getLatLngs());
    }
  }

  const handleOnDeleteStart = (event) => {
    //handle polygon on deleted event 
    console.log("time to delete");
    console.log(event);
    if (event) {
      console.log("delete polygon");
      event.target.closePopup(event.target.carbon_popup);
      // console.log(event.layer.getLatLngs());
    }
  };


  return (
    <div>
      <MapContainer
        center={init_center}
        zoom={init_zoom}
        style={{ width: "100%", height: "100vh", position: "absolute" }}
        scrollWheelZoom={false}
        zoomControl={false}
        rotateControl={false}
        minZoom={5}
        onZoomEnd={hanldeOnZoomEnd}
        ref={setMap}
      >
        <ScaleControl position="bottomleft" ></ScaleControl> {/* scale component to zoom in & zoom out map */}
        {props.isGuess && (<GuessNavBar searchOnMap={searchOnMap} />)} {/* nav bar for public mode */}
        {props.isGuess && (<GuessToolBar forestLayerChanger={setTurnOnLayer1} mangroveLayerChanger={setTurnOnLayer2}
          distanceChanger={changeLegendDistance} addImg={addImg} removeImg={removeImg} />)} {/* tool bar for public mode */}
        {props.isUser && (<UserNavBar searchOnMap={searchOnMap} />)} {/* nav bar for private mode */}
        {props.isUser && (<UserToolBar forestLayerChanger={setTurnOnLayer1} mangroveLayerChanger={setTurnOnLayer2}
          distanceChanger={changeLegendDistance} addImg={addImg} removeImg={removeImg} />)} {/* tool bar for private mode */}
        {canDraw && <FeatureGroup>
          <EditControl
            position="topright"
            edit={
              {
                edit: true,
                poly: true,
              }}
            style={{ top: 100, }}
            onCreated={handleOnDrawCreate}
            onEdited={handleOnEdited}
            onDeleteStart={handleOnDeleteStart}
            onDrawStart={handleOnDrawStart}
            // onDrawStop={handleOnDrawStop}
            draw={
              {
                rectangle: true,
                polygon: true,
                circle: false,
                marker: false,
                circlemarker: false,
                polyline: false,
              }}
          // onDrawCreate={handleOnDrawCreate(e)}
          ></EditControl>
          {/* component for drawing and editing polygon, rectangle */}
        </FeatureGroup>
        }
        <LayerGroup>
          {/* <TileLayer attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          ></TileLayer> */}
          <BaseMap mapRef={map} useMapEvents={useMapEvents} firstLoad={firstLoad} setFirstLoad={setFirstLoad} listBboxes={listBboxes} set_list_bboxes={setListBboxes} listDisplayedImgs={listDisplayedImgs} setListDisplayedImgs={setListDisplayedImgs}
            addCarbonValue={addCarbonValue} subtractCarbonValue={subtractCarbonValue} setCarbonValue={setCarbonValue}
            msgZoomIn={msgZoomIn} setMsgZoomIn={setMsgZoomIn} prevZoomLv={prevZoomLv} setPrevZoomLv={setPrevZoomLv} setCanDraw={setCanDraw} /> {/* based map layer */}
          <ZoomControl position='bottomleft' />
        </LayerGroup>
        {showMarker &&
          <Marker
            key={'marker'}
            position={markerPos}
            draggable={false}
            eventHandlers={{
              click: (e) => {
                console.log(e.latlng);
              }
            }}
          >
            <Popup>
              <button onClick={() => removeMarker(markerPos)}>Remove marker</button>
            </Popup>
          </Marker>
        } {/* marker for location of interest */}
        {turnOnLayer1 &&
          (<LayerGroup color="purple">
            <HeatmapLayer
              fitBoundsOnLoad={false}
              fitBoundsOnUpdate={false}
              zoom={init_zoom}
              points={addressPoints}
              radius={5}
              blur={1}
              longitudeExtractor={m => m[1]}
              latitudeExtractor={m => m[0]}
              intensityExtractor={m => parseFloat(m[2])}
            />
          </LayerGroup>)} {/* layer 1 (old version) */}
        {turnOnLayer1 && <Legend color_list={["#fb0038", "#208b3a", "#7dec57", "#82f453", "#F7b3c2"]} />}
        {turnOnLayer2 && (
          <TiffOverlay map={map} img_file={"empty_bbox.tif"} addCarbonValue={addCarbonValue} subtractCarbonValue={subtractCarbonValue}></TiffOverlay>
        )} {/* layer 2 (old version) */}
        {turnOnLayer2 && <Legend color_list={["#03735c", "#208b3a", "#7dec57", "#82f453", "#b7efc5"]} distance={legendDistance} />}
        {/* {turnOnLayer2 && (<div className="label-widget"> <Text keyboard>Carbon Stock: {carbonValue}</Text> </div>)} */}
        <div className="label-widget"> <Text style={{ color: "#ffffff", fontSize: msgZoomIn ? 36 : 36 }} > {msgZoomIn ? "Please zoom in!" : carbonValue > 0 ? `Carbon Stock: ${Number((carbonValue).toFixed(2))} tCO2e` : "No data!"}</Text> </div> {/* component for showing carbon stock */}
        {listImg.length > 0 && (listImg.map((img) => {
          // map.flyTo(img.bounds[0])
          return (<ImageOverlay className='image-on-map'
            interactive={true}
            // url={"http://194.233.76.251:3000/18/120504/209380.jpeg"}
            url={img.url}
            bounds={img.bounds}
            opacity={img.opacity}
          />)
        }))
        }{/* component for uploading images from user */}
        {/* <Modal open={msgZoomIn} onOk={() => setMsgZoomIn(false)} onCancel={() => setMsgZoomIn(false)} footer={[]}><p>From the scale level of 200m and above, carbon stock calculating are unsupported. Please zoom in to scale level of 100m to see carbon stock!</p></Modal> */}
      </MapContainer>
    </div>

  )
}

export default Map;