import axios from "axios";

export const BASE_URL = 'http://localhost:8002';

export const AI_HOST_BASE_URL = 'http://alexto.ddns.net:8081';

export const client = axios.create({ baseURL: BASE_URL });

export const register = (form) => client.post("register", form).then(v => v.data);

export const login = (form) => client.post("login", form).then(v => v.data);

export const client_ai = axios.create({baseURL: AI_HOST_BASE_URL});

export const getListSegmentations = (coords_json) => axios.get("mapbox/tiles", {params: coords_json}).then(v => v.data)

export const getCarbonStockInPolygon = (coords_json) => axios.post("mapbox/polygon", coords_json).then(v => v.data)
