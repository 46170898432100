import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Image, Row, Col } from 'antd';
import tick from "../../assets/images/tick.png";
import "../../App.css";

function SuccessfullyRegisterForm() {
    return (
        <Form
        layout="horizontal"
        labelCol={{
        span: 6
        }}
        wrapperCol={{
        span: 18
        }}>
            <h1 style={{textAlign: "center"}}><b>REGISTRATION IS DONE!</b></h1>
                <Row justify="center"><Col span={4}><Image src={tick} style={{alignSelf: 'center'}}></Image></Col></Row>
            <p>An e-mail is sent to your e-mail to verify your registration. Please check and click on verification link to complete progress</p>
        </Form>
    )
}
export default SuccessfullyRegisterForm;