import '../App.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import {
    useMap
} from 'react-leaflet';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-rotate/dist/leaflet-rotate.js';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import "leaflet-geosearch/dist/geosearch.css";
import LoginForm from '../components/LoginScreen/LoginForm';
import RegisterForm from '../components/RegisterScreen/RegisterForm';
import SuccessfullyRegisterForm from '../components/RegisterScreen/SuccessfullyRegisterForm';
import { FiHome, FiChevronRight, FiSearch, FiSettings } from "react-icons/fi";

import { ProfileTwoTone, ProjectTwoTone, CloudUploadOutlined, FontColorsOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom";
import { Col, Layout, Row, theme } from 'antd';
import { Button, Modal, Space, Tooltip, Menu, Input, Typography } from 'antd';
import Introduction from '../components/HomeScreen/Intro';
import Map from '../Map.js';
import { useCookies } from 'react-cookie';


const { Header, Content, Sider } = Layout;
const { Text, Link } = Typography;

const items_list = [ProfileTwoTone, CloudUploadOutlined].map((icon, index) => {
    const key = String(index);
    return {
        key: key,
        icon: React.createElement(icon),
    }
})

function GuessHome() {

    const [isGuess, setIsGuess] = useState(true);
    const [isUser, setIsUser] = useState(false);
    const [openIntro, setOpenIntro] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies(['IntroShowed']);
    console.log("Cookie value", cookies)
    // const [cookieShowed, setCookieShowed] = useState(cookies)
    const setIntroCookie = (value) => {
        setCookie("IntroShowed", value, { path: '/' })
    }

    return (
        <div className="map-wrapper">
            <Map isGuess={isGuess} isUser={isUser} /> {/* isGuess is activated for public mode and isUser is activated for private mode  */}
            {!cookies.IntroShowed && <div style={{width: 2000}}><Introduction openIntro={openIntro} setOpenIntro={setOpenIntro} setIntroCookie={setIntroCookie}></Introduction></div>}
        </div>

    );


}
export default GuessHome;