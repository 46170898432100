import '../../App.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import {
    useMap
} from 'react-leaflet';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-rotate/dist/leaflet-rotate.js';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import "leaflet-geosearch/dist/geosearch.css";
import { useNavigate, Navigate } from "react-router-dom";
import { MenuProps } from 'antd';
import { ProfileTwoTone, CloudUploadOutlined, ProjectTwoTone, AppstoreOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Layout, Row, theme } from 'antd';
import { Button, Modal, Space, Dropdown, Menu, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import "../../assets/css/NavBar.css"

const { Header, Content, Sider } = Layout;
const items_list = [ProfileTwoTone, CloudUploadOutlined, AppstoreOutlined, ProjectTwoTone].map((icon, index) => {
    const key = String(index);
    return {
        key: key,
        icon: React.createElement(icon),
    }
})

function UserNavBar({searchOnMap}) {

    const navigate = useNavigate();
    
    const handleLogOut = () => {
        navigate(-1)
    }


    const user_menu_list = [
        {
            key: '1',
            // label: "Logout",
            label: (
                (<Button onClick={() => handleLogOut()}>Logout</Button>)
            ),
        },
        {
            key: '2',
            label: ((<Button>Settings</Button>)),
        },
    ]
    return (
        <div className="navbar-widget">
            <Layout>
                <Header className='topbar' >
                    <Row justify='center'>
                        <Col span={12} align="left" alignItems="center">
                            <Space.Compact>
                            <Input.Search
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    onSearch={(value) => searchOnMap(value)}
                                    defaultValue="Cork City - Ireland"
                                />
                            </Space.Compact>
                        </Col>
                        <Col span={12} align="right">
                            <Space.Compact>
                                <Dropdown menu={{ items: user_menu_list, }} trigger={['click']}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Button>
                                            Viet 98
                                            <DownOutlined />
                                        </Button>
                                    </a>
                                </Dropdown>
                            </Space.Compact>
                        </Col>
                    </Row>
                </Header>
            </Layout>
        </div>
    );
}
export default UserNavBar;