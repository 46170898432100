// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-widget {
    position: absolute;
    top: center;
    left: 10px;
    width: 50px;
    border-radius: 10%;
    z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/ToolBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".toolbar-widget {\n    position: absolute;\n    top: center;\n    left: 10px;\n    width: 50px;\n    border-radius: 10%;\n    z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
