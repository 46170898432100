import loam from 'loam';
import './App.css';
import { Route, Routes } from "react-router-dom";
import GuessHome from "./pages/GuessHome"
import UserHome from './pages/UserHome';
import { useEffect } from 'react';
// require('./libs/loam-worker.js');
// require('./libs/gdal.js');
// require('./libs/gdal.wasm');
// require('./libs/gdal.data');




loam.initialize(`/`, `/`);

function App() {
  console.log('main')
  useEffect(() => {
    console.log("Initialize loam successfully")
  }, []);
  return (
    <div className="App">
       <Routes>
         <Route path="/" element={ <GuessHome /> } />
         <Route path="/user" element={ <UserHome /> } />
      </Routes>
    </div>
  );
}
export default App;
