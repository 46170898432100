import React from "react";
import { FileOutlined, PieChartOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { FiLayers } from 'react-icons/fi';
import { Breadcrumb, Layout, Menu, Modal, Form, FloatButton, message, Upload, Input } from 'antd';
import loam from 'loam';
import { useState } from 'react';
import LayerChoicesForm from "./LayerChoicesForm";
import { read_tiff_file } from '../../libs/TiffTile.jsx';
import '../../assets/css/ToolBar.css';
import UploadImageForm from "../UploadImagesScreen/UploadImageForm";

const { Header, Content, Footer, Sider } = Layout;
const { Dragger } = Upload;



function GuessToolBar({ forestLayerChanger, mangroveLayerChanger, distanceChanger, addImg, removeImg }) {
    // const curFileList = []
    const [fileList, setFileList] = useState([])
    const [showLayerChoices, setShowLayerChoices] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewUid, setPreviewUid] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [uploadImageOpen, setUploadImageOpen] = useState(false);
    const [initUploadImgCord, setInitUploadImgCord] = useState([0,0,0,0])
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );


    const closeLayerChoices = () => {
        setShowLayerChoices(false);
    };

    const openLayerChoices = () => {
        console.log(showLayerChoices)
        setShowLayerChoices(true);
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const handlePreview = async (file) => {
        // console.log("Upload file url", file.url)
        setPreviewImage(file.url || file.preview);
        setPreviewUid(file.uid);
        setPreviewOpen(true);
        // setImgInfoOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        if (!file.url && !file.preview && file.name.match(/.*\.(?:tif|tiff)$/i)) {
            console.log("In handling tiff file")
            // const url = await getBase64(file.originFileObj)
            // console.log("Read file preview", url)
            // await read_tiff_file(url).then((response) => {
            //     file.url = response.imageURL;
            //     const bounds = response.wgs84GeoCorners;
            //     setPreviewImage(file.url || file.preview);
            //     setPreviewUid(file.uid);
            //     setPreviewOpen(true);
            //     // setImgInfoOpen(true);
            //     setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
            // })
        }
        else if (!file.url && !file.preview) {
            console.log("on preview image png/jpg")
            // file.preview = await getBase64(file.originFileObj);
            // setPreviewImage(file.url || file.preview);
            // setPreviewUid(file.uid);
            // setPreviewOpen(true);
            // // setImgInfoOpen(true);
            // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        }
        

    };

    const handlePreviewCancel = () => {
        setPreviewOpen(false);
        console.log("Preview cancel")
    }

    const openUploadImageModal = () => {
        setUploadImageOpen(true);
    };

    const uploadImageCancel = () => {
        setUploadImageOpen(false);
    };

    const finishedPreview = (value) => {
        setPreviewOpen(false);
        console.log("Finished preview")
        console.log(value.topLeftLat);
        console.log(value.topLeftLng);
        console.log(value.bottomRightLat);
        console.log(value.bottomRightLng);
        console.log(value.rotDeg);
        console.log(value.ImgType);
        console.log(previewImage);
        addImg(value, previewImage, previewUid);
    };

    const handleRemove = (file) => {
        console.log(file.uid)
        removeImg(file.uid);
    };

    const uploadImageOk = () => {
        // const idx = 0;
        console.log("Upload Image OK")
        setUploadImageOpen(false);
    };


    const onChangeDragger = (info) => {
        const { status } = info.file;

        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    const onDropDragger = (e) => {
        console.log('Dropped files', e.dataTransfer.files);
    }

    const handleOnChange = async (info) => {
        setFileList(info.fileList);
        if (info.file.status !== 'uploading') {
            console.log("handle on change", fileList);
        }

        if (!info.file.url && !info.file.preview && info.file.name.match(/.*\.(?:jpeg|jpg|png|svg)$/i)) {
            console.log("on preview image png/jpg")
            info.file.preview = await getBase64(info.file.originFileObj);
            setPreviewImage(info.file.url || info.file.preview);
            setPreviewUid(info.file.uid);
            setPreviewOpen(true);
            // setImgInfoOpen(true);
            setPreviewTitle(info.file.name || info.file.url.substring(info.file.url.lastIndexOf('/') + 1));
        }

        if (!info.file.url && !info.file.preview && info.file.name.match(/.*\.(?:tif|tiff)$/i)) {
            const url = await getBase64(info.file.originFileObj)
            console.log("Uploading file tiff", url)
            await read_tiff_file(url).then((response) => {
                info.file.url = response.imageURL;
                const bounds = response.wgs84GeoCorners;
                console.log("Bound of upload tiff: ", bounds)
                setInitUploadImgCord(bounds);
                setPreviewImage(info.file.url || info.file.preview);
                setPreviewUid(info.file.uid);
                // setImgInfoOpen(true);
                setPreviewTitle(info.file.name || info.file.url.substring(info.file.url.lastIndexOf('/') + 1));
            }).then(()=> setPreviewOpen(true));
        }
        // if (info.file.status === 'done') {
        //     message.success(`${info.file.name} file uploaded successfully`);
        // } else if (info.file.status === 'error') {
        //     message.error(`${info.file.name} file upload failed.`);
        // }

    }

    return (
        <div className="toolbar-widget">
            <FloatButton.Group
                shape="square"
                style={{
                    // position: "absolute",
                    top: 100,
                    left: 10,
                    height: 100,
                }}
            >
                {/* <FloatButton icon={<FiLayers />} onClick={() => openLayerChoices()} /> */}
                {/* <FloatButton icon={<PieChartOutlined />} /> */}
                {/* <FloatButton icon={<UploadOutlined />} onClick={openUploadImageModal} /> */}
                {/* </Upload> */}
                <Modal centered open={uploadImageOpen} onCancel={uploadImageCancel} onOk={uploadImageOk}>
                    <Upload
                        // action="/upload_image"
                        crossOrigin="anonymous"
                        listType="picture-card"
                        accept=".png,.jpeg,.tif,.tiff"
                        fileList={fileList}
                        width={1500}
                        height={300}
                        // beforeUpload={}
                        onPreview={async (file) => await handlePreview(file)}
                        onChange={(info) => handleOnChange(info)}
                        onRemove={(file) => handleRemove(file)}
                        multiple={true}
                    >
                        {fileList.length >= 5 ? null : uploadButton}
                    </Upload>
                    <Modal open={previewOpen} title={previewTitle} onCancel={handlePreviewCancel} footer={[]}>
                        <UploadImageForm previewImage={previewImage} finishedPreview={finishedPreview} initCord={initUploadImgCord}></UploadImageForm>
                    </Modal>
                </Modal>
                {/* <FloatButton.BackTop visibilityHeight={50} /> */}
            </FloatButton.Group>
            <Modal className="layerChoices" footer={null}
                centered open={showLayerChoices} onOk={() => closeLayerChoices()} onCancel={() => closeLayerChoices()}>
                <LayerChoicesForm forestLayerChanger={forestLayerChanger} mangroveLayerChanger={mangroveLayerChanger} distanceChanger={distanceChanger}></LayerChoicesForm>
            </Modal>
        </div >
    );
};

export default GuessToolBar;