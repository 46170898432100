// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-widget{
    position: relative;
    /* top: 120px;
    left: 20px; */
    z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/NavBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB;iBACa;IACb,aAAa;AACjB","sourcesContent":[".navbar-widget{\n    position: relative;\n    /* top: 120px;\n    left: 20px; */\n    z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
