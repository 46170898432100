// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend-widget {
    position: relative;
    bottom: 50px;
    right: 10px;
    width: 15px;
    height: 80px;
    border-radius: 10%;
    z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Legend.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".legend-widget {\n    position: relative;\n    bottom: 50px;\n    right: 10px;\n    width: 15px;\n    height: 80px;\n    border-radius: 10%;\n    z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
