import '../App.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import {
    useMap
} from 'react-leaflet';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-rotate/dist/leaflet-rotate.js';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import "leaflet-geosearch/dist/geosearch.css";
import LoginForm from '../components/LoginScreen/LoginForm';
import { useNavigate } from "react-router-dom";
import { MenuProps } from 'antd';
import { ProfileTwoTone, CloudUploadOutlined, ProjectTwoTone, AppstoreOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Layout, Row, theme } from 'antd';
import { Button, Modal, Space, Dropdown, Menu, Input, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Map from '../Map';
import Introduction from '../components/HomeScreen/Intro';

function UserHome () {

    const [isGuess, setIsGuess] = useState(false);
    const [isUser, setIsUser] = useState(true);
    const [openIntro, setOpenIntro] = useState(true);


    return (
            <div className="map-wrapper">
                <Map isGuess={isGuess} isUser={isUser}/> {/* isGuess is activated for public mode and isUser is activated for private mode  */}
                <Introduction openIntro={openIntro} setOpenIntro={setOpenIntro}></Introduction>
            </div>
    );
}
export default UserHome;