import React, { useState } from "react";
import { Button, Checkbox, Form, Input } from 'antd';
import { Space, Typography } from 'antd';
const { Text, Link } = Typography;

const onFinish = (values) => {
    console.log('Success:', values);
  };

const onFinishFailed = (errorInfo) => {
console.log('Failed:', errorInfo);
};

function LoginForm({onLoginSubmit}) {

    return (
        <Form
        name="basic"
        labelCol={{
        span: 6,
        }}
        wrapperCol={{
        span: 16,
        }}
        style={{
        maxWidth: 600,
        }}
        initialValues={{
        remember: true,
        }}
        onFinish={(values) => onLoginSubmit(values)}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
             <p style={{textAlign: "center"}}><b>LOGIN</b></p>
            
            <Form.Item
            label="Username"
            name="username"
            rules={[
                {
                required: true,
                message: 'Please input your username!',
                },
            ]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="Password"
            name="password"
            rules={[
                {
                required: true,
                message: 'Please input your password!',
                },
            ]}
            >
            <Input.Password />
            </Form.Item>

            <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
                offset: 10,
                span: 16,
            }}
            >
            <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Form.Item
            name="forgotPwd"
            wrapperCol={{
                offset: 10,
                span: 16,
              }}>
            <Link href="#" target="_blank">
            Forgot password ?
            </Link>
            </Form.Item>
            <Form.Item wrapperCol={{
                offset: 11,
                span: 16,
            }}>
                {/* <div style={{"position": "fixed", "left": "50vw"}}> */}
                <Button key="submit" type="primary" htmlType="submit">
                    Submit
                </Button> 
                {/* </div> */}
            </Form.Item>
        </Form>
    )
}

export default LoginForm;