import React from "react";
import { Modal, Card, Row, Col, Button, Space } from "antd";
import drharry from "../../assets/images/rsz_harrynguyen.jpg"; 
import profsullivan from "../../assets/images/rsz_barry-barryosullivan.png";
import profpan from "../../assets/images/rsz_shan-pan.png";
import quangng from "../../assets/images/quang.jpg";
import vietphq from "../../assets/images/rsz_vhqp.jpg";
import alex from "../../assets/images/rsz_alex_to.jpg";
const { Meta } = Card;

function Introduction({ openIntro, setOpenIntro, setIntroCookie }) {
    const handleDonotShowAgian = () => {
        setOpenIntro(false);
        setIntroCookie(true);
    };

    return (
        <Modal open={openIntro} onOk={() => setOpenIntro(false)} onCancel={() => setOpenIntro(false)} width={750} 
            footer={[
                <Button key="Do not show again" type="primary" onClick={handleDonotShowAgian}>
                Do not show again
                </Button>,
                <Button key="cancel" type="primary" onClick={() => setOpenIntro(false)}>
                Cancel
                </Button>
                ]}
            >
            <h1 style={{textAlign:"center"}}>Welcome to the Insight Map</h1>
            <p style={{textAlign:"center"}}><b>(Experimental results)</b></p>
            <p><b>This project aims to provide reliable insights for sustainable development goals.</b></p>
            <p><b>Project Leads</b></p>
            {/* <Row gutter={16} style={{display:"-ms-flexbox"}}>
                <Col span={8}> */}
                <div style={{display:"flex", marginInline:5}}>
                <Space size={45} wrap>
                    <Card hoverable
                        bordered
                        size="small"
                        style={{ width: 200, height:350}}
                        cover={<img alt="example" src={drharry} width={200} height={200}/>}>
                        <p><b>Dr Harry Nguyen </b></p>
                        <p><b>(UCC - Ireland)</b></p>
                        {/* <p>Email: hn@cs.ucc.ie</p> */}
                    </Card>
                {/* </Col> */}
                {/* <Col span={8}> */}
                    <Card hoverable
                        bordered
                        size="small"
                        style={{ width: 200, height:350}}
                        cover={<img alt="example" src={profsullivan} width={200} height={200}/>}>
                        <p><b>Professor Barry O'Sullivan</b></p>
                        <p><b>(UCC - Ireland)</b></p>
                        {/* <p>Email: b.osullivan@cs.ucc.ie</p> */}
                    </Card>

                {/* </Col> */}
                {/* <Col span={8}> */}
                    <Card hoverable
                        bordered
                        size="small"
                        style={{ width: 200, height:350 }}
                        cover={<img alt="example" src={profpan} width={200} height={200}/>}>
                        <p><b>Scientia Professor Shan L Pan </b></p>
                        <p><b>(UNSW - Australia)</b></p>
                        {/* <p>Email: shan.pan@unsw.edu.au</p> */}
                    </Card>
                    </Space>
                    </div>
                {/* </Col>
            </Row> */}
            <b>Team members</b>
            {/* <Row gutter={16} style={{display: "-ms-flexbox"}}> */}
                {/* <Col span={8}> */}
                    <div style={{display:"flex", marginInline:5}}>
                    <Space size={45} wrap>
                    <Card hoverable
                        bordered
                        style={{ width: 200, height:350  }}
                        cover={<img alt="example" src={vietphq} width={200} height={200}/>}>
                        <p><b>Viet Pham</b></p>
                        {/* <p>Email: vietuet98@gmail.com</p> */}
                    </Card>
                {/* </Col> */}
                {/* <Col span={8}> */}
                    <Card hoverable
                        bordered
                        style={{ width: 200, height:350  }}
                        cover={<img alt="example" src={quangng} width={200} height={200}/>}>
                        <p><b>Quang Nguyen</b></p>
                        {/* <p>Email: nh.quang313@gmail.com</p> */}
                    </Card>
                {/* </Col> */}
                {/* <Col span={8}> */}
                    <Card hoverable
                        bordered
                        style={{ width: 200, height:350  }}
                        cover={<img alt="example" src={alex} width={200} height={200}/>}>
                        <p><b>Alex To</b></p>
                        {/* <p>Email: tonhudung@gmail.com</p> */}
                    </Card>
                    </Space>
                    </div>
                {/* </Col> */}
            {/* </Row> */}
        </Modal>
    )
};

export default Introduction;