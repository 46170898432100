import '../../App.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-rotate/dist/leaflet-rotate.js';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import "leaflet-geosearch/dist/geosearch.css";
import LoginForm from '../../components/LoginScreen/LoginForm';
import RegisterForm from '../../components/RegisterScreen/RegisterForm';
import SuccessfullyRegisterForm from '../../components/RegisterScreen/SuccessfullyRegisterForm';

import { useNavigate, Navigate } from "react-router-dom";
import { Col, Layout, Row, theme } from 'antd';
import { Button, Modal, Space, Tooltip, Menu, Input, Typography, Form } from 'antd';
import { register, login } from "../../services/api.js";
import "../../assets/css/NavBar.css"

const { Header, Content, Sider } = Layout;
const { Text, Link } = Typography;
// const {register} = api;

function GuessNavBar({ searchOnMap }) {

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isSuccessfullyRegisterModalOpen, setIsSuccessfullyRegisterModalOpen] = useState(false);
    const [failedLoginModal, setFailedLoginModal] = useState(false);
    const [failedRegistrationModal, setFailedRegistrationModal] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [registerForm] = Form.useForm();
    const navigate = useNavigate();

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    }

    const closeRegisterModal = () => {
        setIsRegisterModalOpen(false);
    }

    const closeSuccessfullyRegisterModalOpen = () => {
        setIsSuccessfullyRegisterModalOpen(false);
    }

    const goToLogin = () => {
        setIsSuccessfullyRegisterModalOpen(false);
        setIsLoginModalOpen(true);
    }

    const handleLoginOk = (form) => {
        setLoginLoading(true);
        login({ "username": form.username, "password": form.password }).then((data) => {
            console.log(data);
            if (data.loginStatus) {
                setLoginLoading(false);
                setIsLoginModalOpen(false);
                navigate("/user");
            }
            else {
                setLoginLoading(false);
                setFailedLoginModal(true);
            }
        })
        // setTimeout(() => {
        //     setLoginLoading(false);
        //     setIsLoginModalOpen(false);
        // }, 3000);
        // navigate("/user")
    };

    const handleRegisterOk = (form) => {
        console.log(form)
        register({ "username": form.username, "password": form.password, "email": form.email, "gender": form.gender, "location": form.location ? form.location[0] : null, "agreement": form.agreement }).then((data) => {
            console.log("Register", data.registerStatus);
            if (data.registerStatus) {
                setIsRegisterModalOpen(false);
                setIsSuccessfullyRegisterModalOpen(true);
            }
            else {
                setFailedRegistrationModal(true);
            }
        })

    }

    // render() {
    return (
        <div className="navbar-widget">
            <Layout style={{ position: "relative" }}>
                <Header className='topbar' >
                    <Row justify='center'>
                        <Col span={12} align="left">
                            <Space.Compact>
                                <Input.Search
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    onSearch={(value) => searchOnMap(value)}
                                    defaultValue="Cork City - Ireland"
                                />
                            </Space.Compact>
                        </Col>
                        <Col span={12} align="right">
                            {/* <Space wrap>
                                <Button type="primary" shape="round" onClick={() => { setIsLoginModalOpen(true); }}>Login</Button>
                                <Button type="primary" shape="round" onClick={() => { setIsRegisterModalOpen(true); }}>Register</Button>
                            </Space> */}
                        </Col>
                    </Row>
                </Header>
                <Modal className='loginForm'
                    centered open={isLoginModalOpen} onOk={() => closeLoginModal()} onCancel={() => closeLoginModal()}
                    footer={[]
                        // [<Button key="submit" type="primary" loading={loginLoading} onClick={() => handleLoginOk()}>Submit</Button>,
                        // <Button key="back" onClick={() => closeLoginModal()}>Cancel</Button>]
                    }>
                    <LoginForm onLoginSubmit={(form) => handleLoginOk(form)}></LoginForm>
                </Modal>
                <Modal className='registerForm'
                    centered open={isRegisterModalOpen} onOk={() => closeRegisterModal()} onCancel={() => closeRegisterModal()}
                    footer={[]}>
                    {/* [<Button key="submit" type="primary" onClick={() => handleRegisterOk()}>Submit</Button>,
                    [<Button key="back" onClick={() => closeRegisterModal()}>Cancel</Button>]}> */}
                    <RegisterForm onRegisterSubmit={(form) => handleRegisterOk(form)}></RegisterForm>
                </Modal>
                <Modal className='successfullyRegisterForm'
                    centered open={isSuccessfullyRegisterModalOpen} onOk={() => closeSuccessfullyRegisterModalOpen()} onCancel={() => closeSuccessfullyRegisterModalOpen()}
                    footer={
                        [<Button key="goToLogin" style={{ flexDirection: "row" }} type="primary" onClick={() => goToLogin()}>Go To Login</Button>]
                    }>
                    <SuccessfullyRegisterForm></SuccessfullyRegisterForm>
                </Modal>
                <Modal className='failedLogin' centered open={failedLoginModal} onOK={() => setFailedLoginModal(false)} onCancel={() => setFailedLoginModal(false)}
                        footer={[]}>
                    <h1>Incorrect username or password</h1>
                </Modal>
                <Modal className='failedRegister' centered open={failedRegistrationModal} onOK={() => setFailedRegistrationModal(false)} onCancel={() => setFailedRegistrationModal(false)}
                        footer={[]}>
                    <h1>Incorrect username or password</h1>
                </Modal>
            </Layout>
        </div>
    );
}
// }
export default GuessNavBar;