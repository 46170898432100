import React from "react";
import { FileOutlined, PieChartOutlined, UserOutlined } from '@ant-design/icons';
import { FiLayers } from 'react-icons/fi';
import { FloatButton } from 'antd';
import { useState } from 'react';
import "../../assets/css/Legend.css"
import { icon } from "leaflet";

function Legend({color_list, distance}){
    // const legend = color_list.map((color) => <i style={{backgroundColor:{color}}} ></i>)

    return (
        <div className="legend-widget" >
            <FloatButton.Group
                        shape="square"
                        style={{
                            // position: "absolute",
                            bottom: 50,
                            right: 50+distance,
                            height:100,
                            width: 20,
                            backgroundImage: `linear-gradient(0deg, ${color_list[4]}, ${color_list[0]})`,
                        }}
            >
            </FloatButton.Group>
        </div>
    );
}

export default Legend;