// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-widget {
    position: fixed;
    bottom: 20px;
    right: 10px;
    border-radius: 5%;
    z-index: 1000;
    font-style: bold;
    font-size: large;
    color:#ffffff;
    background-color:#012f01;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Label.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,wBAAwB;AAC5B","sourcesContent":[".label-widget {\n    position: fixed;\n    bottom: 20px;\n    right: 10px;\n    border-radius: 5%;\n    z-index: 1000;\n    font-style: bold;\n    font-size: large;\n    color:#ffffff;\n    background-color:#012f01;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
