import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
  } from 'antd';

  const { Option } = Select;
  const residences = [
    {
        value: 'Indonesia',
        label: 'Indonesia',
    },
    {
        value: 'Malaysia',
        label: 'Malaysia',
    },
    {
        value: 'Singapore',
        label: 'Singapore',
    },
    {
        value: 'Vietnam',
        label: 'Vietnam',
    },
    {
        value: 'Australia',
        label: 'Australia',
    },
    {
        value: 'Ireland',
        label: 'Ireland',
    },
  ];
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 12,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 6,
      },
    },
  };

function RegisterForm({onRegisterSubmit}) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };
    return (
        <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={(values) => onRegisterSubmit(values)}
        initialValues={{
            residence: ['Ireland', 'Australia', 'Vietnam', 'Singapore', 'Malaysia', 'Indonesia'],
            prefix: '86',
        }}
        style={{
            maxWidth: 600,
        }}
        scrollToFirstError
        >
            <p style={{textAlign: "center"}}><b>REGISTER</b></p>
            <Form.Item
                name="username"
                label="Username"
                rules={[
                {
                    required: true,
                    message: 'Please input your username!',
                },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="password"
                label="Password"
                rules={[
                {
                    required: true,
                    message: 'Please input your password!',
                },
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                {
                    required: true,
                    message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="location"
                label="Location"
                rules={[
                {
                    // type: 'array',
                    required: true,
                    message: 'Please select your habitual residence!',
                },
                ]}
            >  
                <Cascader options={residences} />
          
            </Form.Item>

            <Form.Item
                name="gender"
                label="Gender"
                rules={[
                {
                    required: true,
                    message: 'Please select gender!',
                },
                ]}
            >
                <Select placeholder="select your gender">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                {
                    validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                },
                ]}
                {...tailFormItemLayout}
            >
                <Checkbox>
                I agree with the <a href="">terms</a> and <a href="">privacy policy</a>.
                </Checkbox>
                
            </Form.Item>
            <Form.Item style={{"display": "relative"}}>
                <div style={{"position": "fixed", "left": "50vw"}}>
                <Button key="submit" type="primary" htmlType="submit">
                    Submit
                </Button> 
                </div>
            </Form.Item>
        </Form>
    )
}
export default RegisterForm;